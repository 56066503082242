<template>
  <div class="short-description-wrapper">
    <el-popover
      width="320"
      ref="popy"
      trigger="click"
      :popper-class="popperClass"
    >
      <button
        class="button-tools button-help bg-white hover_bg-reallylightblue"
        type="button"
        slot="reference"
      >
        <help-icon />
      </button>
      <p class="button-info" @click="shortDescription">
        {{ $t('guide.short_instruction') }}
      </p>
      <a class="button-info" href="https://wiki.skif.pro/" target="_blank">{{
        $t('guide.title')
      }}</a>
      <div class="button-info container-feedback" @click="handleFeedback">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 16.3636C13.2928 16.3636 16.3636 13.2928 16.3636 9C16.3636 4.70724 13.2928 1.63636 9 1.63636C4.70724 1.63636 1.63636 4.70724 1.63636 9C1.63636 13.2928 4.70724 16.3636 9 16.3636ZM9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18ZM5.95666 6.75584C6.01677 5.2397 7.05202 4.09091 8.98894 4.09091C10.7522 4.09091 11.9411 5.13952 11.9411 6.56883C11.9411 7.60408 11.4201 8.3321 10.5385 8.85974C9.67688 9.36735 9.42975 9.72134 9.42975 10.4093V10.8167H8.04719L8.04051 10.2824C8.00712 9.34063 8.41454 8.73952 9.32957 8.19184C10.1377 7.70427 10.4249 7.31688 10.4249 6.62894C10.4249 5.87421 9.8305 5.31985 8.91547 5.31985C7.99376 5.31985 7.39933 5.87421 7.33922 6.75584H5.95666ZM8.74849 13.9091C8.26092 13.9091 7.87354 13.5351 7.87354 13.0475C7.87354 12.5599 8.26092 12.1859 8.74849 12.1859C9.24942 12.1859 9.63013 12.5599 9.63013 13.0475C9.63013 13.5351 9.24942 13.9091 8.74849 13.9091Z"
            fill="#0071C5"
          />
        </svg>
        <p class="button-feedback">{{ $t('guide.quiestion_offer') }}</p>
      </div>
    </el-popover>

    <transition name="slide-fade">
      <div
        v-if="isShortDescription"
        :class="[
          'short-description',
          { 'short-description--isOpen': isShortDescription }
        ]"
      >
        <div class="short-description__header">
          <p v-if="$route.fullPath === '/'" class="header__title">
            {{ $t('guide.instructions') }}
          </p>
          <p v-else class="header__title" @click="$router.go(-1)">
            {{ $t('guide.back') }}
          </p>
          <close-icon
            class="header__close"
            @click.native="closeShortDescription"
          />
        </div>
        <div class="short-description__body">
          <div v-if="$route.path === '/'" class="menu-one">
            <div class="link">
              <img src="@/components/map/tools/help-tools/images/lkDiler.svg" />
              <router-link class="router-link" :to="{ path: 'lk-diler' }">
                {{ $t('guide.personal_account') }}
              </router-link>
            </div>
            <div class="link">
              <img
                src="@/components/map/tools/help-tools/images/workPlaceCompany.svg"
              />
              <router-link
                class="router-link"
                :to="{ path: 'work-place-company' }"
              >
                {{ $t('guide.company_workplace') }}
              </router-link>
            </div>
          </div>

          <router-view />
        </div>
        <div class="short-description__footer">
          <img
            v-if="$route.fullPath === '/'"
            class="footer-img"
            src="@/components/map/tools/help-tools/images/car.png"
            alt=""
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios'
import { mapMutations } from 'vuex'
import HelpIcon from '@/components/map/tools/images/HelpIcon.vue'
import CloseIcon from '@/components/map/tools/images/CloseIcon.vue'

export default {
  components: {
    HelpIcon,
    CloseIcon
  },
  data() {
    return {
      page: '',
      isShortDescription: false
    }
  },
  computed: {
    popperClass() {
      return !this.isShortDescription
        ? 'popover-help-tools'
        : 'popover-help-tools popover-help-tools--isOpen'
    }
  },

  watch: {
    isShortDescription(newVal) {
      this.setShortDescription(newVal)
    }
  },

  methods: {
    ...mapMutations({
      setShortDescription: 'helpTools/setShortDescription'
    }),
    shortDescription() {
      this.isShortDescription = !this.isShortDescription
    },
    closeShortDescription() {
      this.isShortDescription = false
      if (this.$route.fullPath === '/') return
      this.$router.push({ path: '/' })
    },
    handleFeedback() {
      this.$emit('feedback')
      this.$refs.popy.doClose()
    }
  }
}
</script>

<style lang="stylus">
.popover-help-tools
  padding 15px
  &--isOpen
    z-index 0!important
</style>

<style lang="stylus" scoped>
.button-tools
  display flex
  align-items center
  justify-content center
  width 2.25rem
  height 2.25rem
  border-radius 50%

.button-info
  font-size 14px
  font-weight 400
  color #5477A9
  cursor pointer
  &:first-child
    margin-bottom 13px

.eldrawer {
  &-title {
    font-size 14px
  }
}

.short-description
  position fixed
  background #fff
  top 0
  right -377px
  bottom 0
  width 377px
  padding 25px 16px
  box-shadow 0px 0px 10px #cbc2c2
  z-index 9

  &--isOpen
    right 0
    overflow-y auto

  &__header
    display flex
    align-items center
    justify-content space-between

    .header
      &__title
        font-weight 700
        font-size 18px
        color #5477A9
        cursor pointer
      &__close
        cursor pointer

  &__body
    margin-top 34px
    .link
      display flex
      align-items center
      .router-link
        color #5477A9

  &__footer
    .footer-img
      position absolute
      bottom 60px
      left 50%
      transform translate(-50%, 0)

.bcg-image
  position absolute
  bottom 0
  left 50%
  transform translate(-50%, -60px)
.container-feedback
  display flex
  color #0071C5
  padding-top 12px
  margin-top  12px
  border-top 1px solid #E6EAEE
  & svg
    margin-right:10px

.button-help
  background-color #0071C5
</style>
